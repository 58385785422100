import discordIcon from '../../../assets/discordicon.png'
import helpCenterIcon from '../../../assets/helpCenter.png'
import UpperRightArrow from '../../../assets/UpperRightArrow.png'


export const FAQData = [
    {
        question: 'What is Bluecore Protocol?',
        answer: 'Bluecore is an automated market maker. In practical terms, it is a collection of smart contracts that define a standard way to create liquidity pools, provide liquidity, and swap assets.'
    },
    {
        question: 'What is Bluecore Protocol?',
        answer: 'Bluecore is an automated market maker. In practical terms, it is a collection of smart contracts that define a standard way to create liquidity pools, provide liquidity, and swap assets.'
    },
    {
        question: 'How do I use the Bluecore Protocol?',
        answer: 'Bluecore is an automated market maker. In practical terms, it is a collection of smart contracts that define a standard way to create liquidity pools, provide liquidity, and swap assets.'
    },
    {
        question: 'How does Bluecore Protocol work?',
        answer: 'Bluecore is an automated market maker. In practical terms, it is a collection of smart contracts that define a standard way to create liquidity pools, provide liquidity, and swap assets.'
    },
]

export const FaqsData2 = [
    {
        question: 'How does Bluecore Protocol work?',
        answer: 'Bluecore is an automated market maker. In practical terms, it is a collection of smart contracts that define a standard way to create liquidity pools, provide liquidity, and swap assets.'
    },
    {
        question: 'What is Bluecore Protocol?',
        answer: 'Bluecore is an automated market maker. In practical terms, it is a collection of smart contracts that define a standard way to create liquidity pools, provide liquidity, and swap assets.'
    },
    {
        question: 'What is Bluecore Protocol?',
        answer: 'Bluecore is an automated market maker. In practical terms, it is a collection of smart contracts that define a standard way to create liquidity pools, provide liquidity, and swap assets.'
    },
    {
        question: 'How do I use the Bluecore Protocol?',
        answer: 'Bluecore is an automated market maker. In practical terms, it is a collection of smart contracts that define a standard way to create liquidity pools, provide liquidity, and swap assets.'
    },
]

export const FAQButton = [
    { icon: discordIcon, text: 'Discord', alt:"discordIocn", link: "https://discord.com/invite/bedDtDeBrY" },
    { icon: helpCenterIcon, text: 'Help Center', alt:'Help Center', link:"" },
]
// {[FAQData.slice(0, FAQData.length / 2), FAQData.slice(FAQData.length / 2)].map((data, index) => (

// {
//     FAQData.map((data, index) => (
//       <Accordion.Item className='faqs_box' eventKey={index}>
//         <Accordion.Header className='faqs_box'>{data.question}</Accordion.Header>
//         <Accordion.Body className='faqs_box'>{data.answer}</Accordion.Body>
//       </Accordion.Item>
//     ))
//   }