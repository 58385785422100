import React from 'react'
import './Community1.css'
import reddit from '../../../assets/reddit.png'
import discord from '../../../assets/discord-icon.png'
import card_background from '../../../assets/blucode-card.png'
import btn_card from '../../../assets/btn-card-icon.png'
import icon1 from '../../../assets/blueCoreIcon1.png'
import icon2 from '../../../assets/blueDexIcon.png'
import icon3 from '../../../assets/bluePrintIcon.png'
import download_icon from '../../../assets/download_icon.png'
import newDownload from '../../../assets/newDownloadIcon.svg'
import { Link } from 'react-router-dom'
import overlay from '../../../assets/Ellipse 1.png'
import card_overlay from '../../../assets/cardOverlay.png'
import community from '../../../assets/community_background.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const cardData = [
    {
        img: discord,
        title: "DISCORD",
        content: "Feel free to inquire and become involved in discussions within the Bluecore Community.",
        link: "https://discord.com/invite/bedDtDeBrY",
        color: "#19FFFF",


    },
    {
        img: discord,
        title: "CORELABS",
        content: "Stay updated on the latest developments from Bluecore Labs.",
        link: "https://twitter.com/BlueCoreLabs",
        color: "#9F88DE",
    }
    ,
    {
        img: discord,
        title: "POLICY FORUM",
        content: "Participate in Bluecore Governance, share your insights and ideas.",
        link: "",
        color: "#1975FF",
    },

]

const cardsPlay = [
    {
        para: 'UPDATE',
        title: "BLUECODE",
        subtitle: 'A virtual hackathon centered around the BLUECORE Protocol.',
        text: "Learn More",
    },
    {
        para: 'UPDATE',
        title: "BLUECODE",
        subtitle: 'A virtual hackathon centered around the BLUECORE Protocol.',
        text: "Learn More",
        overlay: card_overlay,
    },
    {
        para: 'UPDATE',
        title: "BLUECODE",
        subtitle: 'A virtual hackathon centered around the BLUECORE Protocol.',
        text: "Learn More",
    },

]

const cardBrandAsset = [
    {
        img: icon1,
        title: "BlueCore",
        subtitle: "Download",
        img2: download_icon,
    },
    {
        img: icon2,
        title: "BlueDex",
        subtitle: "Download",
        img2: download_icon,

    },
    {
        img: icon3,
        title: "BluePrint",
        subtitle: "Download",
        img2: download_icon,

    },
]

export default function Community1() {
    return (
        <>
            <div className='community1'>
                <div className="main_conatiner">

                    <div className='container py-5 d-flex flex-column justify-content-center align-items-center h-100 gap-5'>
                        <h1 className="main_title text-center">The BLUECORE ecosystem comprises users, developers, designers, and educators</h1>

                        <p className='subtile-para px-2 my-3'>
                            <span className='d-lg-block'>Bluecore's thriving global community is a cornerstone of the</span>
                            <span className='d-lg-block'>Protocol's achievements. Engage in discussions on platforms such as Discord,</span>
                            <span className='d-lg-block'>Twitter, and Reddit to stay updated on the latest developments and community</span>
                            <span className='d-lg-block'>news, highlighting the interconnectedness driving the success of Bluecore's ecosystem.</span>
                        </p>
                    </div>
                </div>
                <div className='section_2_comunity1 py-5 d-flex flex-column justify-content-center align-items-center'>
                    <div className='d-flex flex-wrap card-social justify-content-center gap-5 my-3'>
                        {cardData.map((item, idx) => (
                            <Link as={Link} to={item.link} target='_blank' key={idx} className='social_box position-relative'>
                                {/* <img className='mb-2' src={item.img} alt="" loading='lazy' /> */}
                                <p className='social_Title'>{item.title}</p>
                                <p className='social_Para'>{item.content}</p>
                                <div className='position-absolute bottom-0' style={{ height: '2px', width: '100px', background: `${item.color}` }}></div>
                            </Link>
                        ))}

                    </div>
                </div>
                <div className='section_3_community1 py-5'>
                    <div className='d-flex flex-column align-items-center'>
                        <p className='text-white join-text text-uppercase mb-4 text-center'>serious play fosters meaningful progress</p>
                        <p className='join-para px-2 text-white fs-5 mb-5 w-50 text-center text-capitalize'>BLUECORE community thrives by contributing and expressing themselves through diverse channels that extend beyond financial topics.</p>
                    </div>

                    <div className='d-flex flex-wrap justify-content-center gap-5 mx-3'>
                        {cardsPlay.map((data, idx) => (
                            <div className='blucode_card position-relative'>
                                <div className='card-bg text-white'>
                                    <p className='blu_Update'>{data.para}</p>
                                    <p className='blu_Title'>{data.title}</p>
                                    <p className='blu_Para'>{data.subtitle}</p>
                                </div>
                                <div>
                                    <button className='btn_card'>{data.text}</button>

                                </div>
                                <div className='w-100'>
                                    <img src={card_overlay} alt="" className={`${idx === 1 ? 'd-block' : 'd-none'} w-100 position-absolute start-0 bottom-0`} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='section_4_community1'>
                    <div className='brand_assetContainer'>
                        <p className='asset-para'>Download</p>
                        <p className='join-text'>Brand assets</p>

                        <div className="assestCard_container position-relative">
                            <div className="assetCard_body ">

                                {
                                    cardBrandAsset.map((data, idx) => (
                                        <div className='d-flex flex-column justify-content-center align-items-center gap-1 position-relative'>
                                            <img src={data.img} alt="" width={125} />
                                            <p className='mb-0 asset-txt'>{data.title}</p>
                                            <button className='mb-0 asset_down'>{data.subtitle} <img src={newDownload} alt="" /></button>
                                        </div>
                                    ))
                                }

                                <div className='img_overlay position-absolute'>
                                    <img src={overlay} alt="" width={'96%'} />
                                </div>

                            </div>
                        </div>


                    </div>





                </div>
            </div>
        </>
    )
}
