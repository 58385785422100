import React, { useContext, useEffect } from 'react'
import './Community.css'
import { Button } from 'react-bootstrap'
import UpperArrow from '../../../assets/svg/LearnMore.png'
import countImage from '../../../assets/CountImg.png'
import { communityBCR, GovernanceApp } from './CommunityData';
import { Link } from 'react-router-dom'

export default function Community() {

    return (
        <>
            <div className='community_main'>
                <div className='community_bg'>
                    <div className="container main_section1">

                        <p className="h1 commSec1_title">
                            Bdex token holders govern
                            <span className='title_Subtext mx-3'>
                                BLUECORE
                            </span>
                        </p>
                        <p className='sec1_para'>
                            <span className='d-md-block'>The BLUECORE token empowers community ownership and</span>{' '}
                            <span className='d-md-block'>active stewardship of the Protocol. BLUECORE holders govern the Protocol through an on-chain governance process</span></p>
                        <Link to="https://beta-docs.bluedex.xyz/contracts/v3/reference/governance/overview" target='_blank'><button className='buttonCumm_main'>BDEX Token</button></Link>
                    </div>
                </div>

                <div className='container-xxl d-flex flex-column gap-4 py-5'>
                    <div className="sec_header">
                        <p className="h1 section2_title mb-3">Governance Applications</p>
                        <p className='sec2_description'>Express your views to influence the protocol's future</p>
                    </div>
                    <div className="section2_cards">
                        {
                            GovernanceApp.map((data, index) => (
                                <div className="sec2_card position-relative">
                                    <p className="h2 sec2Para_title">{data.title}</p>
                                    <p className='card_para mt-3'>{data.description}</p>
                                    <div className='bottom_color'></div>
                                </div>
                            ))

                        }
                    </div>
                </div>
            </div>

            <div className="section3">
                <div className='section3_Wrapper mx-auto '>
                    <p className="h1 sece_headingtitle">Governance Procedure</p>
                    <div className="container-xxl sec3_cards">
                        <div className='GroupCard'>
                            {communityBCR.map((data, index) => (
                                <div className="card_details position-relative">

                                    <div className='secImg position-absolute'>
                                        <img src={data.img} alt="" />
                                    </div>
                                    <div className="seccardTitle">
                                        {data.title}
                                    </div>
                                    <div className="sec3CardDesc">
                                        {data.description}

                                    </div>
                                    <Button className='button_main'>Learn more</Button>{' '}
                                </div>
                            ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

