import React from 'react'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'
import "./home.css"
import FeatureBox from './FeatureBox';
import Offers from './Offers';
import OperatesButton from './OperatesButton';


export default function Home() {

    return (
        <>
            <div className='home_one'>
                <div className='home_section'>
                    <div className='home_items'>
                        <h1 className='headingOneText'>Empowering Ideas Fuelling Innovation</h1>
                        <p className='short-text my-3 text-center'>Where projects and investors reach new heights together.</p>
                        <Link to='https://beta.bluedex.xyz/' target='_blank'><Button className='btnLaunch mt-3'>Launch app</Button></Link>
                    </div>
                </div>

                <FeatureBox />
                <Offers />
                <OperatesButton />
            </div>

        </>
    )
}
