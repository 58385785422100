// communityData.js

import sunImage from '../../../assets/svg/card2img.png';
import coinImage from '../../../assets/svg/card1img.png';
import boxImage from '../../../assets/svg/card3img.png';
import one from '../../../assets/1.png'
import two from '../../../assets/2.png'
import three from '../../../assets/3.png'

export const communityBCR = [
  {
    img: one,
    title: 'Submit a Bluecore Request for Comment (BRC).',
    description: 'We invite you to actively participate in the Bluecore ecosystem by submitting a Bluecore Request for Comment (BRC). Share your proposals, ideas, and feedback, engaging in open dialogue and collaborative decision-making that shapes our community\'s future. Your contributions are highly valued as we work together to achieve our shared vision.'
  },
  {
    img: two,
    title: 'Create a snapshot',
    description: 'Generate a snapshot by capturing a moment in time, often through a digital record or image, preserving the status or data at that specific instant for future reference or analysis. Snapshots are essential in various contexts, from data management to photography and memory preservation.'
  },
  {
    img: three,
    title: 'Submit Bluecore BIP',
    description: 'Propose and submit an official Bluecore Improvement Proposal, a formalized way to suggest changes or enhancements to the Bluecore ecosystem. These proposals can encompass various aspects, such as protocol upgrades, governance changes, or new features, contributing to the continuous development and evolution of the Bluecore platform.'
  },
];



export const GovernanceApp = [
  {
    image: sunImage,
    title: 'Governance Platform',
    description: 'An avenue for governance-related discourse. Share proposals, provide feedback, and collaborate with the Bluecore community to shape the future of the protocol on this platform.',
  },
  {
    image: coinImage,
    title: 'Governance Gateway',
    description: 'The endorsed governance voting portal. Peruse real-time governance proposals and securely cast your vote on the blockchain for active participation in shaping the future of the protocol.',
  },
  {
    image: boxImage,
    title: 'VOTING PLATFORM',
    description: 'An avenue for governance-related discourse. Share proposals, provide feedback, and collaborate with the Bluecore community to shape the future of the protocol on this platform.',
  },
];
