
import React, { useState } from 'react'
import './Ecosystem.css'
import { CiSearch } from "react-icons/ci";
import metamask from '../../../assets/Ecosystem/MetaMask.png'
import trust from '../../../assets/Ecosystem/Trust Wallet.png'
import etherwallet from '../../../assets/Ecosystem/MyEtherWallet.png'
import exodus from '../../../assets/Ecosystem/Exodus.png'
import coinomi from '../../../assets/Ecosystem/Coinomi.png'
import atomic from '../../../assets/Ecosystem/Atomic Wallet.png'
import ledger from '../../../assets/Ecosystem/Ledger Live.png'
import trezor from '../../../assets/Ecosystem/Trezor Suite.png'
import fortmatic from '../../../assets/Ecosystem/Fortmatic.png'
import mathwallet from '../../../assets/Ecosystem/Math Wallet.png'
import portis from '../../../assets/Ecosystem/Portis.png'
import phantom from '../../../assets/Ecosystem/Phantom.png'
import edgewallet from '../../../assets/Ecosystem/Edge Wallet.png'
import coingecko from '../../../assets/Ecosystem/CoinGecko.png'
import huobi from '../../../assets/Ecosystem/Huobi Wallet.png'
import binance from '../../../assets/Ecosystem/Binance Chain.png'
import nifty from '../../../assets/Ecosystem/Nifty Wallet.png'
import harmony from '../../../assets/Ecosystem/Harmony ONE.png'
import ellipal from '../../../assets/Ecosystem/Ellipal.png'
import rainbow from '../../../assets/Ecosystem/Rainbow.png'
import zelcore from '../../../assets/Ecosystem/ZelCore.png'
import arkane from '../../../assets/Ecosystem/Arkane.png'
import gnonis from '../../../assets/Ecosystem/Gnosis Safe.png'
import coinrequest from '../../../assets/Ecosystem/CoinRequest.png'
import status from '../../../assets/Ecosystem/Status.png'
import dapper from '../../../assets/Ecosystem/Dapper.png'
import piller from '../../../assets/Ecosystem/Piller.png'
import jaxx from '../../../assets/Ecosystem/Jaxx Liberty.png'
import { Link } from 'react-router-dom';
import infoIcon from '../../../assets/Ecosystem/Info.png'


export default function Ecosystem() {


    const [searchQuery, setSearchQuery] = useState('');

    const cardsData = [
        {
            img: metamask,
            title: "MetaMask",
            subtitle: "Ethereum-based, offers web3 integration for DApps",
            link: 'https://metamask.io/',
        },
        {
            img: trust,
            title: "Trust Wallet",
            subtitle: "Mobile wallet with built-in DApp browser",
            link: 'https://metamask.io/',
        },
        {
            img: etherwallet,
            title: "MyEtherWallet",
            subtitle: "Client-side wallet for Ethereum and ERC-20 tokens",
            link: 'https://www.myetherwallet.com/',
        },
        {
            img: exodus,
            title: "Exodus",
            subtitle: "Multi-currency wallet with exchange features",
            link: 'https://www.exodus.com/',
        },
        {
            img: coinomi,
            title: "Coinomi",
            subtitle: "Multi-asset wallet with privacy options",
            link: 'https://www.coinomi.com/en/',
        },
        {
            img: atomic,
            title: "Atomic Wallet",
            subtitle: "Desktop wallet with built-in exchange capabilities",
            link: 'https://atomicwallet.io/',
        },
        {
            img: ledger,
            title: "Ledger Live",
            subtitle: "Manages hardware wallet Ledger devices",
            link: 'https://www.ledger.com/ledger-live',
        },
        {
            img: trezor,
            title: "Trezor Suite",
            subtitle: "Software to manage Trezor hardware wallets",
            link: 'https://trezor.io/trezor-suite',
        },
        {
            img: fortmatic,
            title: "Fortmatic",
            subtitle: "Provides developer tools for wallet integration",
            link: 'https://fortmatic.com/',
        },
        {
            img: mathwallet,
            title: "Math Wallet",
            subtitle: "Multi-chain wallet with cross-chain functionality",
            link: 'https://mathwallet.org/en-us/',
        },
        {
            img: portis,
            title: "Portis",
            subtitle: "Non-custodial wallet for DApps",
            link: 'https://www.portis.io/',
        },
        {
            img: phantom,
            title: "Phantom",
            subtitle: "Solana-based wallet with browser extension",
            link: 'https://phantom.app/',
        },
        {
            img: edgewallet,
            title: "Edge Wallet",
            subtitle: "Mobile wallet with multiple blockchain support",
            link: 'https://edge.app/'
        },
        {
            img: coingecko,
            title: "CoinGecko",
            subtitle: "Integrates with CoinGecko for tracking assets",
            link: 'https://www.coingecko.com/',
        },
        {
            img: huobi,
            title: "Huobi Wallet",
            subtitle: "Official wallet for Huobi Exchange",
            link: 'https://www.huobiwallet.io/en',
        },
        {
            img: binance,
            title: "Binance Chain",
            subtitle: "Wallet for Binance Smart Chain (BSC)",
            link: 'https://www.bnbchain.org/en',
        },
        {
            img: nifty,
            title: "Nifty Wallet",
            subtitle: "Ethereum wallet with integrated staking features",
            link: 'https://secuxtech.com/products/nifty-nft-wallet',
        },
        {
            img: harmony,
            title: "Harmony ONE",
            subtitle: "Wallet for the Harmony blockchain",
            link: 'https://www.harmony.one/',
        },
        {
            img: ellipal,
            title: "Ellipal",
            subtitle: "Air-gapped hardware wallet with mobile app",
            link: 'https://www.ellipal.com/',
        },
        {
            img: rainbow,
            title: "Rainbow",
            subtitle: "Ethereum wallet with token management and DApps",
            link: 'https://rainbow.me/en/',
        },
        {
            img: zelcore,
            title: "ZelCore",
            subtitle: "Multi-asset wallet with portfolio tracking",
            link: 'https://zelcore.io/',
        },
        {
            img: arkane,
            title: "Arkane Network",
            subtitle: "Blockchain-agnostic wallet with gaming integration",
            link: 'https://www.venly.io/',
        },
        {
            img: gnonis,
            title: "Gnosis Safe",
            subtitle: "Secure and customizable Ethereum wallet",
            link: 'https://safe.global/',
        },
        {
            img: coinrequest,
            title: "CoinRequest",
            subtitle: "Open-source crypto payment requests and wallet",
            link: 'https://coinrequest.io/',
        },
        {
            img: status,
            title: "Status",
            subtitle: "Mobile wallet with built-in messaging and browsing",
            link: 'https://status.app/',
        },
        {
            img: dapper,
            title: "Dapper",
            subtitle: "Ethereum wallet for blockchain games and collectibles",
            link: 'https://meetdapper.com/',
        },
        {
            img: piller,
            title: "Pillar",
            subtitle: "Personal data and wallet control app",
            link: 'https://www.pillar.fi/',
        },
        {
            img: jaxx,
            title: "Jaxx Liberty",
            subtitle: "Multi-currency wallet with cross-platform support",
            link: 'https://jaxx-liberty-trade-crypto.en.softonic.com/android',
        },
    ]


    const filteredCards = cardsData.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className='ecosystem_Main'>
            <div className='ecosystem_container'>
                <div className='container eco_HeadTitle'>

                    <h1 className='ecosystem_header'>Discover the Bluecore Ecosystem</h1>
                    <p className='eco-para'>Trade, earn, vote, and more with DeFi apps on Bluecore.</p>
                </div>

            </div>
            <div className='eco_section_1'>
                <div className='container explore_pro'>

                    {/* <h2 className='eco_title'>Explore projects constructed on the Bluecore Protocol</h2>
                    <p className='eco_para'>Creating something remarkable with Bluecore? Share your project.</p> */}
                    <div className='input_wrapper'>
                        <input
                            className='input_box'
                            type="text"
                            placeholder='Search'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <CiSearch size={25} className='text-white' />
                    </div>
                </div>

                <div className='card_wrapper d-flex flex-wrap justify-content-center mt-5'>

                    {filteredCards.map((item, idx) => (
                        <Link as={Link} to={item.link} target='_blank' className='eco_cards' key={idx}>
                            <img src={item.img} className='mb-2' alt={item.title} />
                            <h2 className='card_tilte text-uppercase'>{item.title}</h2>
                            <p className='card_desc text-center'>{item.subtitle}</p>
                            <div className='bottom_color'></div>
                        </Link>
                    ))}

                </div>

                <div className='container bottom_para_div'>
                    <img src={infoIcon} alt="" />
                    <p className='bottom_para'>Information about applications, services, and websites not developed, controlled, or tested by Bluecore is offered without endorsement. Bluecore assumes no liability regarding the choice, performance, security, accuracy, or utilization of third-party services. For additional details on each project, kindly reach out to the developer.</p>
                </div>
            </div>

        </div>
    )
}
