import React, { useContext, useEffect, useState } from 'react'
import './Faqs.css'
import UpperRightArrow from '../../../assets/UpperRightArrow.png'
import dootedLine from '../../../assets/dottedLine.png'
import Accordion from 'react-bootstrap/Accordion';
import { FAQData, FAQButton } from './FaqsData'
import { Link } from 'react-router-dom';

export default function Faqs() {

  const [cardialActive, setCardialActive] = useState(4)
  const openCordial = (index) => {
    setCardialActive(index === cardialActive ? null : index)
  }

  console.log('cardialActive === 1', cardialActive);

  return (
    <>

      <section className='faq_mainContainer'>
        <div className="Faqs_main">
          <div className="container faq_sec1 ">
            <p className="h1 Faqsec1_para">Frequently Asked Questions</p>
            <div className="social_btn">
              {
                FAQButton.map((data, index) => (
                  <Link as={Link} to={data.link} target='_blank' className="social_btns">
                    <div className='d-flex align-items-center justify-content-center'>
                      <span className='btn_para'>{data.text}</span>
                    </div>

                  </Link>
                ))
              }

            </div>
            <p className='para_Bottom'>You have questions, we have answers. Feel free to ask more!</p>
          </div>
        </div>
        <div className="faqs_section2">
          <div className="container-xxl faqs_mainbox">
            <div className="faqs_mainboxleft">
              <Accordion className='accordion_layout'>
                <Accordion.Item className={`faqs_box ${cardialActive === 0 ? 'activeCardial' : 'cccc'}`} eventKey="0" onClick={() => openCordial(0)}>
                  <Accordion.Header className={`faqs_box_head ${cardialActive === 0 ? '' : 'faq_bg'}`}>How does the no-code token creator work?</Accordion.Header>
                  <Accordion.Body className='faqs_box'>
                    BLUEPRINT aims to allow anyone to design and launch their own token not only on the blockchain but directly to our DEX without needing programming knowledge. You can choose from various token types and customise features like supply limits and transaction fees to suit your project's needs and take control of your vision.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`faqs_box ${cardialActive === 1 && 'activeCardial'}`} eventKey="1" onClick={() => openCordial(1)}>
                  <Accordion.Header className={`faqs_box_head ${cardialActive === 1 ? '' : 'faq_bg'}`}>What types of tokens can I create with Blueprint?</Accordion.Header>
                  <Accordion.Body>
                    Users can create Core, Deflationary, or Dividend tokens, each offering different features and customisation options. This variety ensures flexibility in meeting your project's specific requirements and goals.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`faqs_box ${cardialActive === 2 ? 'activeCardial' : 'cccc'}`} eventKey="2" onClick={() => openCordial(2)}>
                  <Accordion.Header className={`faqs_box_head ${cardialActive === 2 ? '' : 'faq_bg'}`}>What customisation options are available for tokens created on Blueprint?</Accordion.Header>
                  <Accordion.Body>
                    Blueprint provides an extensive range of customisation options to fine-tune your token. Beyond setting transaction fees and reward mechanisms, you can adjust supply limits, implement buy and sell fees, define wallet holding limits, and specify a minimum balance for dividends. Additionally, advanced features such as anti-whale measures and automatic liquidity pool contributions are available. These tools are designed not only to cater to your project's unique needs but also to ensure a user-friendly and stable launch process.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`faqs_box ${cardialActive === 3 ? 'activeCardial' : 'cccc'}`} eventKey="3" onClick={() => openCordial(3)}>
                  <Accordion.Header className={`faqs_box_head ${cardialActive === 3 ? '' : 'faq_bg'}`}>Are Blueprint tokens compatible with existing wallets and exchanges?</Accordion.Header>
                  <Accordion.Body>
                    Yes, tokens created within the Bluecore ecosystem are designed to be compatible with a wide range of wallets and exchanges that support the Base chain and any other chains our token creator supports, facilitating ease of access and use across the DeFi landscape..
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}
