import React from 'react'
import arrow from '../../../assets/arrowNew.svg'
import './home.css'
import IndigenousImg from '../../../assets/Indigenous.png'
import { Button } from 'react-bootstrap';
import UpperArrow from '../../../assets/svg/LearnMore.png'
import { Link } from 'react-router-dom';

export default function FeatureBox() {


    const homeCards = [
        {
            title: "Swap",
            subtitle: "Exchange tokens swiftly on the BASE platform by simply linking your wallet.",
            btnTxt: "Learn more",
            color: "#FA12FF"

        },
        {
            title: "NFT POOLS",
            subtitle: "Commit your NFTs for the opportunity to receive exclusive rewards.",
            btnTxt: "Learn more",
            color: "#50FF12"
        },

    ]

    const homeCards2 = [
        {
            title: "Indigenous dex",
            subtitle: "Bluecore enables the enhancement of your rewards via the Concentrated Liquidity Market Maker (CLMM).",
            btnTxt: "Learn more",
            color: "#FFD512"

        },
        {
            title: "Corepad",
            subtitle: "Acquire the most promising gems available on BASE.Exchange tokens swiftly on the BASE platform by simply linking your wallet.",
            btnTxt: "Learn more",
            color: "#12D4FF"
        },
    ]

    return (
        <>
            <div className='featureBoxAlig d-flex flex-column'>
                <div className='row d-flex flexe-column justify-content-center gap-5'>
                    <div className='col-lg-5 p-0 d-flex justify-content-center justify-content-lg-start'>
                        <p className='join_comm_para'>Join our community of innovation</p>
                    </div>
                    <div className='col-lg-5 d-flex flex-column align-items-center align-items-lg-start p-0'>
                        <p className='para_nums'>$389.1M+</p>
                        <p className='text_trade'>Trade Volume</p>
                        <p className='para_nums'>$670K+</p>
                        <p className='text_trade'>Total Value Locked</p>
                        <p className='para_nums'>200K+</p>
                        <p className='text_trade'>Community Members</p>
                    </div>

                </div>

                <div className='home_cards_wrapper d-flex justify-content-center'>
                    <div className='container row mt-5'>
                        <div className='col-lg-3 mt-5 justify-content-center'>
                            <p className='para_dive'>Dive in to our
                                Ecosystem.</p>
                        </div>
                        <div className='col-lg-8 dive_cards d-flex flex-wrap gap-5'>
                            <div className='mt-5 pt-5'>
                                {homeCards.map((data, idx) => (
                                    <div className='home_dive_cards mb-5'>
                                        <div>
                                            <p className='txt_title' style={{ borderLeft: `2px solid ${data.color}` }}>{data.title}</p>
                                            <p className='txt_para'>{data.subtitle}</p>
                                        </div>
                                        <div>
                                            <p className='txt_lernMore'>{data.btnTxt}<img className='ms-3' src={arrow} alt="" /></p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>
                                {homeCards2.map((data, idx) => (
                                    <div className='home_dive_cards mb-5'>
                                        <div>
                                            <p className='txt_title' style={{ borderLeft: `2px solid ${data.color}` }}>{data.title}</p>
                                            <p className='txt_para'>{data.subtitle}</p>
                                        </div>
                                        <div>
                                            <p className='txt_lernMore'>{data.btnTxt} <img className='ms-3' src={arrow} alt="" /></p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
