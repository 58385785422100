import React, { useContext, useEffect } from 'react'
import './TokenInfo.css'
import { Button } from 'react-bootstrap'
import info from '../../../assets/info.png'
import contract from '../../../assets/Vector.png'
import sdk from '../../../assets/sdk.png'
import { Link } from 'react-router-dom'
import newArrow from '../../../assets/arrowNew.svg'

const data = [
  {
    logo: info,
    name: 'What is Bluecore?',
    desc: 'Discover key Bluecore Protocol elements: Swaps, Pools, Concentrated Liquidity, and more.',
    btn: 'Learn more'
  },
  {
    logo: contract,
    name: 'Contract Overview?',
    desc: "Inspect Bluecore's smart contracts, encompassing Core and Periphery libraries.",
    btn: 'Learn more'
  },
  {
    logo: sdk,
    name: 'Bluecore V3 SDK?',
    desc: 'Bluecore V3 SDK assists developers in JavaScript to interact with the protocol.',
    btn: 'Learn more'
  },

]



export default function TokenInfo() {

  return (
    <>
      <div className='developer_container'>
        <div className="token_infoPage">
          <div className="intro_size">
            <div>
              <p className="h1 m-0 intro_title">Develop with </p>
              <p className="h1 m-0 intro_title">Bluecore Protocol</p>
            </div>
            <p className='m-0 intro_descri'>Explore DeFi apps, integrations, and tools on the Bluecore Protocol</p>
            <Link to="https://beta-docs.bluedex.xyz/contracts/v3/overview" target='_blank'>

              <Button className='v3_btn'>V3 Documentation</Button>
            </Link>
          </div>
        </div>

        <div className='dev-card-container py-5 container-fluid'>
          <div className='row d-flex flex-wrap dev-cards justify-content-center align-items-center'>
            <div className='d-flex flex-wrap justify-content-center gap-5 my-4'>
              {
                data.slice(0, 3).map((data) => {
                  return <div className='grey-card py-4 px-3 position-relative'>
                    <p className='card-head'>{data.name}</p>
                    <p className='mb-0 card-desc'>{data.desc}</p>
                    <button className='btn_txt'>{data.btn} <img src={newArrow} alt="" /></button>
                  </div>
                })
              }
            </div>


          </div>

          <div className='gitContainer'>
            <button className='git_button'>GITHUB</button>
          </div>
        </div>
      </div>
      <div className='subConatiner py-5'>

        <div className='container d-flex justify-content-md-end justify-content-sm-center'>

          <div className='big_card'>
            <div className='wrapperSub'>
              <div className='contentSub'>
                <p className='title_app'>Submit an application to the Bluecore Grants Program</p>
                <p className='mt-3 card-desc'>The Bluecore Grants Program supports independent developers and contributors by providing resources for experimentation. Through funding and guidance, the Grants team nurtures talent, enhancing the Bluecore ecosystem and guiding the Protocol toward sustainable community-driven expansion.</p>
                <button className='cardapp_btn'>Learn more <img className='ms-3' src={newArrow} alt="" /></button>
              </div>
            </div>
          </div>

        </div>

        <div className='container d-flex justify-content-sm-center justify-content-md-start'>

          <div className='big_card'>
            <div className='wrapperSub'>
              <p className='title_app'>Bug Hunting Program</p>
              <p className='card-desc'>Bluecore is incentivizing bug hunters by offering rewards of up to $500,000 for uncovering critical or highly severe vulnerabilities within the Protocol's core contracts. This initiative promotes the identification and resolution of security issues, enhancing the platform's reliability and security. Happy hunting for these critical bugs!</p>
              <div className='d-flex flex-wrap gap-4 mt-3'>
                <Button className='cardapp_btn'>Submit a Report <img className='ms-3' src={newArrow} alt="" /></Button>
                <Button className='cardapp_btn'>Learn more <img className='ms-3' src={newArrow} alt="" /></Button>
              </div>
            </div>
          </div>

        </div>


      </div>
    </>
  )
}
