import React from 'react'
import { Card, Container } from 'react-bootstrap'
import './home.css'
import newArrow from '../../../assets/arrowNew.svg'
import cardOverlay from '../../../assets/homeCardOverlay.png'

const data = {
    card1: [
        {
            // img: CaerdImage1,
            head1: "Derivatives in a decentralized setting",
            head2: "Our Launchpad fuels innovation, turning visionary ideas into reality with dedicated support, making it project's ideal home."
        },
        {
            // img: CaerdImage2,
            head1: "Limit order book in decentralized systems",
            head2: "Distributed Network For Sustainability Without Sacrificing Matching Engine Or TPS Performance.",
        },
        {
            // img: CaerdImage3,
            head1: "Data Oracles In Decentralized Context",
            head2: "Price Oracles Will Provide The Current Asset Price In Real-Time To Compose The Underlying Index",
        },
        {
            // img: CaerdImage4,
            head1: "Open And Verifiable Trading",
            head2: "On-Chain Trade Settlement, Liquidations, And Fees Guarantee Full Transparency",
        },
        {
            // img: CaerdImage5,
            head1: "Rewards For Enhancing Liquidity",
            head2: "Trading Fees Are Distributed Among Both Takers And Market Makers",
        },
        {
            // img: CaerdImage6,
            head1: "Self-Control And Unrestricted Access",
            head2: "Customers Link Their Crypto-Wallet And Secure Their Funds Within The Smart Contract, Similar To The Usual DEX Process",
        }
    ]
}


export default function Offers() {
    return (
            <div className='offer_main d-flex flex-column'>
                <div className="container offerHeader w-100">
                    <p className='offerTitlePara'>Why Bluecore?</p>
                    <p className="offertitle">Core offerings</p>
                </div>
                {
                    Object.entries(data).map(([key, value])=>{
                        return <div className="container offerBody d-flex justify-content-center">
                            {
                                value.map((data, idx)=>{
                                    return <Card className='offerCardLeft'>
                                        <Card.Img variant="left" src={data.img} />
                                        <div className='cardRBody'>
                                            <p className='featureTxt'>feature</p>
                                            <Card.Title className='titleTypo'>{data.head1}</Card.Title>
                                            <Card.Text className='desciTypo'>{data.head2}</Card.Text>
                                            <p className='lernTypo'>Learn more <img src={newArrow} alt="" /></p>
                                            
                                        </div>
                                        <img src={cardOverlay} alt="" className={`${idx === 4 ? 'd-block':'d-none'} overLay w-100 position-absolute start-0 bottom-0`} />
                                    </Card>
                                })
                            }
                        </div>
                    })


                }
            </div>
    )
}
