import React from 'react'
import './home.css'
import connectWallet from '../../../assets/wallet 4.png'
import depositFunds from '../../../assets/money-bag.png'
import buySell from '../../../assets/swap-exchange 5.png'
import trackPosition from '../../../assets/liquidity.png'
import overlay from '../../../assets/purpleOvelay.png'
import UpperArrow from '../../../assets/svg/LearnMore.png'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function OperatesButton() {


const operatorCards = [
    {
        img: connectWallet,
        title: "Connect Your Wallet",
        subtitle: "",
    },
    {
        img: depositFunds,
        title: "Deposit Funds",
        subtitle: "",
    },
    {
        img: buySell,
        title: "Buy and Sell Derivatives",
        subtitle: "",
    },
    {
        img: trackPosition,
        title: "Track Your Position",
        subtitle: "",
    },
]

    return (
        <>
            <div className='operatorbtn_main'>
                <div className="container operator_body position-relative">
                    <div className="header">
                        <div>
                            <p className="opratorTypo opratorSubTitle">A brief handbook</p>
                            <p className="operatorTitle mb-3">How it operates</p>
                        </div>
                        <div className='d-flex flex-column'>
                            <p className='opratorTypoPara'>
                                <span className='d-block'>Four steps must be followed</span> 
                                <span className='d-block'>to initiate trading on the stock exchange:</span>
                            </p>
                        </div>
                    </div>
                    <div className="opterator_ButtonBox">
                        <div className="operatorBody">

                            {operatorCards.map((data, idx)=>(
                                 <div className='d-flex flex-column align-items-center'>
                                 <img className='operImgSize' src={data.img} alt="" />
                                 <p className='btnText m-0'>{data.title}</p>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className='imgOverlay position-absolute'>
                        <img src={overlay} alt="" width={'96%'} />
                    </div>

                </div>


            </div>
        </>
    )
}
