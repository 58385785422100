import React from 'react'
import './Blogs.css'

export default function Blogs() {
  return (
    <div className='blog_main'>
      <h1 >Blogs Page Under Developing</h1>
      
    </div>
  )
}
