// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog_main{
    width: 100%;
    height: 90vh;
    background-color:#212121;
    padding: 10rem;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Blogs/Blogs.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,wBAAwB;IACxB,cAAc;IACd,YAAY;AAChB","sourcesContent":[".blog_main{\n    width: 100%;\n    height: 90vh;\n    background-color:#212121;\n    padding: 10rem;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
