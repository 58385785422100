import React from "react";
import './Style.css';
import dotfooter from '../../../assets/svg/dot-footer.png';
import SearchArrow from '../../../assets/svg/SearchArrow.png';
import FotterImage1 from '../../../assets/FooterImage1.png';
import FotterImage2 from '../../../assets/FooterImage2.png';
import FotterImage3 from '../../../assets/FooterImage3.png';
import FooterLine from '../../../assets/FotterLine.png'
import {Link} from 'react-router-dom'
const Footer = () => {

    const footerItem = [
      {
        name: 'Home', 
        link: '/'
      },
      {
        name: 'Community',
        link: '/community'
      },
      {
        name: 'Governance', 
        link: '/governance'
      },
      {
        name: 'Developer', 
        link: '/developers'
      },
      
    ];
    
  return (
    <div className="FootWrapper py-5">
      <div className="footer-2 col-6 col-sm-12">
        <div className="footer-body-left">
          {footerItem.map((item, index) =>{
            return(<Link className='text-decoration-none' to={item.link}><p key={index}>{item.name}</p></Link>);
            })
          }
        </div>
        <div className="footer-body-right">
          <p>bluecore@copyrightsreserved</p>
          <div className="footerPolicy d-flex">
            <p>Privacy Policy</p>
            <p className="mx-4"><img src={dotfooter} alt="dot" /></p>
            <p>Terms of Service</p>
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block">
        <img src={FooterLine} alt="FooterLine" />
      </div>
      <div className="footer-3 col-6 col-sm-12">
        <div>
          <div className="footer-right-text">
            <div className="right-child-box">
              <div className="FooterRight">subscribe </div>
              <p className="Text">Sign up to our newsletter for future updates and other information</p>
            </div>
          </div>
          <div className="rightBox-2">
            <div className="search-box">
              <input className="input_email" type="email" placeholder="youremail@domain.com" />
              <img className="Searcharrow" src={SearchArrow} alt="arrow" />
            </div>
          </div>
        </div>
        <div className="footerImage">
          <Link to="https://www.dextools.io/app/en/base/pair-explorer/0x7fe959644e52056d4f005bfe08eb5fa8e86e61c3?t=1712990420068" target="_blank"><img src={FotterImage1} alt="" /></Link>
          <Link to="https://www.dextools.io/app/en/base/pair-explorer/0x7fe959644e52056d4f005bfe08eb5fa8e86e61c3?t=1712990384457" target="_blank"><img src={FotterImage3} alt="" /></Link>
          <Link to="https://twitter.com/BlueCoreLabs" target="_blank"><img src={FotterImage2} alt="" /></Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
