import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import BrandLogo from "../../assets/Long_Purple 1.png"
import './Style.css'

export default function Header() {
    const location = useLocation();
    const match = (loc)=>{
        if(location.pathname === loc) return true;
        return false;
    }
    return (
        <Navbar expand="lg" className="bg-transparent ps-4 pe-4 py-4 navbar_mainbox" style={{ height: 'max-content', overflowX: 'hidden', zIndex: '2' }}>
            <div className='navLeftItem d-flex justify-content-between'>
                <div>
                    <Navbar.Brand as={Link} className='text-white' to="/">
                        <img
                            alt="logo"
                            src={BrandLogo}
                            className=""

                        />
                    </Navbar.Brand>
                </div>
                <div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className='custom-toggler' />
                    <Navbar.Collapse id="basic-navbar-nav" className='justify-content-between navCollapse'>
                        <Nav className="justify-content-end me-auto text-white navbarText" >
                            <Nav.Link as={Link} className={`text-white ${match('/') && 'active_link'}`} to="/">Home</Nav.Link>
                            <Nav.Link as={Link} className={`text-white ${match('/community') && 'active_link'}`} to="/community">Community</Nav.Link>
                            <Nav.Link as={Link} className={`text-white ${match('/governance') && 'active_link'}`} to="/governance">Governance</Nav.Link>
                            <Nav.Link as={Link} className={`text-white ${match('/developers') && 'active_link'}`} to="/developers">Developer</Nav.Link>
                            <Nav.Link as={Link} className={`text-white ${match('/ecosystem') && 'active_link'}`} to="/ecosystem">Ecosystem</Nav.Link>
                            <Nav.Link as={Link} className={`text-white ${match('/faqs') && 'active_link'}`} to="/faqs">FAQ</Nav.Link>
                        </Nav>
                        <Link to='https://beta.bluedex.xyz/ ' target='_blank'><button className='btnLaunch text-white'>Launch app</button></Link>
                    </Navbar.Collapse>
                </div>
            </div>

        </Navbar>
    )
}
